import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useState } from 'react'
import CanComp3Individual from "../components/candidates3/candidates3componentindividual"



class candidates3 extends React.Component {
  constructor({ data, location }) {
    
    super({ data, location });
    this.state = {
      mainFilter: "constituency"
    }
    
    this.siteTitle = data.site.siteMetadata.title;
    this.location = location;
  }

  handlefilterMain = e => {
    const value = e.target.value;

    this.setState({
      mainFilter: value
    });
  };
  

  render() {
    return (
      <Layout location={this.location} title={this.siteTitle}>
      <Seo title="candidates3" />
      
      <CanComp3Individual></CanComp3Individual>
     
    </Layout>
    );
  }
}

export default candidates3

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`